import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { ErrorApp } from '../web/error-page/ErrorApp'
export const Fragment = (props: any) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)
  useSwanStyleKeys(['core'])

  return <ErrorApp {...props} />
}
