import { LocaleProvider } from '@vp/digital-locale-lib-ubik'
import { HtmlAttributes } from '@vp/digital-site-layout-lib-ubik'
import { useSwanStyleKeys } from '@vp/ubik-context'
import WixWebsitesErrorPage from './src/WixWebsitesErrorPage'

export function ErrorApp (props: any) {
  // Use the hook to apply the Swan style keys
  useSwanStyleKeys(['core', 'button'])
  return (
    <LocaleProvider rawLocaleIdentifier={props.locale}>
      <HtmlAttributes />
      <WixWebsitesErrorPage {...props} />
    </LocaleProvider>
  )
}
