import React, { useEffect, useState } from 'react'
import Markdown from 'markdown-to-jsx'
import styles, { stylesheet } from './WixWebsitesErrorPage.scss'

import {
  BoundedContent,
  Box,
  Column,
  FluidImage,
  GridContainer,
  Row,
  Button,
  Typography,
} from '@vp/swan'
import {
  FeatureGroupModel,
  TextBlockModel,
} from '@vp/digital-common-data-model-lib-ubik'
import { SEOContainer } from '@vp/digital-seo-lib-ubik'
import { TokenReplacer, LinkFactory } from '@vp/digital-token-replacer-lib-ubik'

import Redirect from '../../common/Redirect'
import { wixErrorTypeEnum } from './wixErrorTypeEnum'
import { useLogger, useStyles } from '@vp/ubik-context'

const GENERIC_ERROR_KEY = 'wixGenericError'
const GEO_LOCATION_ERROR_KEY = 'wixGeoLocationError'
const MISSING_SITE_ERROR_KEY = 'wixMissingSiteError'
const MISSING_PACKAGE_ERROR_KEY = 'wixMissingPackageError'
const NOT_IMPLEMENTED_ERROR_KEY = 'wixNotImplementedError'
const SERVICE_NOT_AVAILABLE_ERROR_KEY = 'wixServiceNotAvailableError'
const NOT_PROVISIONED_ERROR_KEY = 'wixNotProvisionedError'
const BACKGROUND_IMAGE_KEY = 'backgroundImage'
const LOGO_IMAGE_KEY = 'logo'

const WixWebsitesErrorPage = (pageContext : any) => {
  const logger = useLogger()
  useStyles(stylesheet)
  const { errorGroup, seo } = pageContext.pageData
  logger.info('errorGroup is = ', errorGroup)

  const { localizedUrls } = pageContext
  const [redirectPath, setRedirectPath] = useState(null)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [errorType, setErrorType] = useState(wixErrorTypeEnum.GenericError)

  // Gatsby build doesn't have a window, need to check here
  const VP_HOST = typeof window !== 'undefined' ? window.location?.host : ''

  let errorPageBackgroundImage: TextBlockModel
  let errorPageLogo: TextBlockModel

  let errorPageData: FeatureGroupModel
  let queryErrorType: string = null
  let subscriptionGroupAltId: string = null

  if (typeof window !== 'undefined' && window.location?.search) {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    )
    queryErrorType = urlParams?.get('ErrorType')?.toLowerCase()
    subscriptionGroupAltId = urlParams?.get('subscriptionGroupAltId')
  }

  const getErrorPageData = (): FeatureGroupModel => {
    let errorPageData: any

    switch (errorType) {
      case wixErrorTypeEnum.GenericError:
        errorPageData = errorGroup?.find(
          ({ key }: { key: string }) => key === GENERIC_ERROR_KEY
        )
        errorPageBackgroundImage = errorPageData.media?.find(
          ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
        )
        errorPageLogo = errorPageData.media?.find(
          ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
        )
        errorPageData = {
          ...errorPageData,
          description: (
            <TokenReplacer
              template={errorPageData.description}
              replacements={{
                link: LinkFactory(localizedUrls),
                contactUsPhone: pageContext.careChannels?.phone?.phoneNumber,
              }}
              convertMarkdown
            />
          ),
          errorPageBackgroundImage,
          errorPageLogo,
        }
        break
      case wixErrorTypeEnum.MissingSiteError:
        errorPageData = errorGroup?.find(
          ({ key }: { key: string }) => key === MISSING_SITE_ERROR_KEY
        )
        errorPageBackgroundImage = errorPageData.media?.find(
          ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
        )
        errorPageLogo = errorPageData.media?.find(
          ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
        )
        errorPageData = {
          ...errorPageData,
          description: (
            <TokenReplacer
              template={errorPageData.description}
              replacements={{
                link: LinkFactory(localizedUrls),
                contactUsPhone: pageContext.careChannels?.phone?.phoneNumber,
              }}
              convertMarkdown
            />
          ),
          errorPageBackgroundImage,
          errorPageLogo,
        }
        break
      case wixErrorTypeEnum.MissingPackageError:
        errorPageData = errorGroup?.find(
          ({ key }: { key: string }) => key === MISSING_PACKAGE_ERROR_KEY
        )
        errorPageBackgroundImage = errorPageData.media?.find(
          ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
        )
        errorPageLogo = errorPageData.media?.find(
          ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
        )
        errorPageData = {
          ...errorPageData,
          description: (
            <TokenReplacer
              template={errorPageData.description}
              replacements={{
                link: LinkFactory(localizedUrls),
                contactUsPhone: pageContext.careChannels?.phone?.phoneNumber,
              }}
              convertMarkdown
            />
          ),
          errorPageBackgroundImage,
          errorPageLogo,
        }
        break
      case wixErrorTypeEnum.WixNotImplementedError:
        errorPageData = errorGroup?.find(
          ({ key }: { key: string }) => key === NOT_IMPLEMENTED_ERROR_KEY
        )
        errorPageBackgroundImage = errorPageData.media?.find(
          ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
        )
        errorPageLogo = errorPageData.media?.find(
          ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
        )
        errorPageData = {
          ...errorPageData,
          description: (
            <TokenReplacer
              template={errorPageData.description}
              replacements={{
                link: LinkFactory(localizedUrls),
                contactUsPhone: pageContext.careChannels?.phone?.phoneNumber,
              }}
              convertMarkdown
            />
          ),
          errorPageBackgroundImage,
          errorPageLogo,
        }
        break
      case wixErrorTypeEnum.GeoLocationError:
        errorPageData = errorGroup?.find(
          ({ key }: { key: string }) => key === GEO_LOCATION_ERROR_KEY
        )
        errorPageBackgroundImage = errorPageData.media?.find(
          ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
        )
        errorPageLogo = errorPageData.media?.find(
          ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
        )
        errorPageData = {
          ...errorPageData,
          errorPageBackgroundImage,
          errorPageLogo,
        }
        break
      case wixErrorTypeEnum.ServiceNotAvailable:
        errorPageData = errorGroup?.find(
          ({ key }: { key: string }) => key === SERVICE_NOT_AVAILABLE_ERROR_KEY
        )
        errorPageBackgroundImage = errorPageData.media?.find(
          ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
        )
        errorPageLogo = errorPageData.media?.find(
          ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
        )
        errorPageData = {
          ...errorPageData,
          description: (
            <TokenReplacer
              template={errorPageData.description}
              replacements={{
                contactUsPhone: pageContext.careChannels?.phone?.phoneNumber,
              }}
              convertMarkdown
            />
          ),
          errorPageBackgroundImage,
          errorPageLogo,
        }
        break
      case wixErrorTypeEnum.WixNotProvisionedError:
        errorPageData = errorGroup?.find(
          ({ key }: { key: string }) => key === NOT_PROVISIONED_ERROR_KEY
        )
        errorPageBackgroundImage = errorPageData.media?.find(
          ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
        )
        errorPageLogo = errorPageData.media?.find(
          ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
        )
        errorPageData = {
          ...errorPageData,
          errorPageBackgroundImage,
          errorPageLogo,
          description: <Markdown>{errorPageData.description}</Markdown>,
        }
        break
    }
    return errorPageData
  }

  const sectionPrimaryCallback = (): (() => void) => {
    let callback
    switch (errorType) {
      case wixErrorTypeEnum.GenericError:
      case wixErrorTypeEnum.MissingSiteError:
      case wixErrorTypeEnum.MissingPackageError:
      case wixErrorTypeEnum.ServiceNotAvailable:
        callback = () => {
          try {
            logger.debug(`Clicking ${errorType} Error Page Primary CTA`)
            window.history.back() // TODO: Placeholder for generic CTA behaviour
          } catch (error) {
            logger.error(
              `sectionPrimaryCallback: Failed to handle ${errorType} Error Page Primary CTA click event.`,
              {
                response: error && error.response,
                status: error && error.response && error.response.status,
                error,
              }
            )
          }
        }
        break
      case wixErrorTypeEnum.GeoLocationError:
        callback = () => {
          try {
            logger.debug(`Clicking ${errorType} Error Page`)
            window.location.href = 'https://' + VP_HOST
          } catch (error) {
            logger.error(
              `sectionPrimaryCallback: Failed to handle click event on ${errorType} Error Page`,
              {
                response: error && error.response,
                status: error && error.response && error.response.status,
                error,
              }
            )
          }
        }
        break
      case wixErrorTypeEnum.WixNotImplementedError:
        callback = () => {
          try {
            if (subscriptionGroupAltId) {
              logger.debug(
                `Clicking ${errorType} Error Page for subscriptionGroupAltId: ${subscriptionGroupAltId}`
              )
              setIsRedirecting(true)
              setRedirectPath(
                localizedUrls.subscriptionManageUrl +
                  '/' +
                  subscriptionGroupAltId
              )
            } else {
              logger.debug(
                'Clicking Wix Not Implemented Missing subscriptionGroupAltId Error Page Primary CTA'
              )
              window.history.back()
            }
          } catch (error) {
            logger.debug(
              `An error occurred while Clicking ${errorType} Error Page :`,
              {
                response: error && error.response,
                status: error && error.response && error.response.status,
                error,
              }
            )
          }
        }
        break
      case wixErrorTypeEnum.WixNotProvisionedError:
        callback = () => {
          try {
            if (subscriptionGroupAltId) {
              logger.debug(
                `Clicking ${errorType} Error Page for subscriptionGroupAltId: ${subscriptionGroupAltId}`
              )
              setIsRedirecting(true)
              setRedirectPath(
                localizedUrls.subscriptionManageUrl +
                  '/' +
                  subscriptionGroupAltId
              )
            } else {
              logger.debug(
                'Clicking Wix Not Provisioned Missing subscriptionGroupAltId Error Page Primary CTA'
              )
              window.history.back()
            }
          } catch (error) {
            logger.error(
              `sectionPrimaryCallback: An error occurred while clicking ${errorType} Error Page Primary CTA`,
              {
                response: error && error.response,
                status: error && error.response && error.response.status,
                error,
              }
            )
          }
        }
        break
    }
    return callback
  }

  const sectionSecondaryCallback = (): (() => void) => {
    let callback
    switch (errorType) {
      case wixErrorTypeEnum.GenericError:
      case wixErrorTypeEnum.MissingSiteError:
      case wixErrorTypeEnum.MissingPackageError:
      case wixErrorTypeEnum.WixNotImplementedError:
      case wixErrorTypeEnum.WixNotProvisionedError:
      case wixErrorTypeEnum.ServiceNotAvailable:
        callback = () => {
          try {
            logger.debug(`Clicking ${errorType} Site Error Page Secondary CTA`)
            window.location.href = 'https://' + VP_HOST
          } catch (error) {
            logger.error(
              `sectionSecondaryCallback: Failed to handle ${errorType} Error Page Secondary CTA click event:`,
              {
                response: error && error.response,
                status: error && error.response && error.response.status,
                error,
              }
            )
          }
        }
        break
    }
    return callback
  }

  /**
   * Override the CTAs of the input data with CTAs of the Generic error page.
   * @param {FeatureGroupModel} dataToOverride The shopper id
   * @returns {FeatureGroupModel} Updated data
   */
  const overrideCTAs = (
    dataToOverride: FeatureGroupModel
  ): FeatureGroupModel => {
    const defaultData = getErrorPageData()
    dataToOverride.features = defaultData.features
    return dataToOverride
  }

  errorPageData = getErrorPageData()
  if (
    errorType === wixErrorTypeEnum.WixNotImplementedError &&
    !subscriptionGroupAltId
  ) {
    errorPageData = overrideCTAs(errorPageData)
  }

  useEffect(() => {
    switch (queryErrorType) {
      case wixErrorTypeEnum.GeoLocationError.toLocaleLowerCase():
        setErrorType(wixErrorTypeEnum.GeoLocationError)
        break
      case wixErrorTypeEnum.MissingSiteError.toLocaleLowerCase():
        setErrorType(wixErrorTypeEnum.MissingSiteError)
        break
      case wixErrorTypeEnum.MissingPackageError.toLocaleLowerCase():
        setErrorType(wixErrorTypeEnum.MissingPackageError)
        break
      case wixErrorTypeEnum.WixNotImplementedError.toLocaleLowerCase():
        setErrorType(wixErrorTypeEnum.WixNotImplementedError)
        break
      case wixErrorTypeEnum.ServiceNotAvailable.toLocaleLowerCase():
        setErrorType(wixErrorTypeEnum.ServiceNotAvailable)
        break
      case wixErrorTypeEnum.WixNotProvisionedError.toLocaleLowerCase():
        setErrorType(wixErrorTypeEnum.WixNotProvisionedError)
        break
      default: // Keep at Generic Error
    }
  }, [queryErrorType])

  return (
    <Redirect redirectPath={redirectPath} isRedirecting={isRedirecting}>
      <>
        <SEOContainer content={seo} />
        {/* Wix Error hero */}
        {errorPageData && (
          <Box className={styles.wixErrorPageHeroContainer}>
            <Box className={styles.wixErrorPageHeroContent}>
              <BoundedContent>
                <GridContainer>
                  <Row>
                    <Column span={6} py={8}>
                      {errorPageLogo && (
                        <FluidImage
                          className={styles.wixErrorPageHeroLogo}
                          src={errorPageLogo.mediaUrl}
                          alt={errorPageLogo.altText}
                        />
                      )}
                      {errorPageData.title && (
                        <Typography
                          m={0}
                          mt='6'
                          component='h1'
                          textColor='standard'
                          fontSize='x3large'
                          darkMode
                        >
                          {errorPageData.title}
                        </Typography>
                      )}
                      {errorPageData.description && (
                        <Typography
                          m={0}
                          mt='5'
                          component='p'
                          textColor='standard'
                          darkMode
                        >
                          {errorPageData.description}
                        </Typography>
                      )}
                      <Box mt='7'>
                        {errorPageData.features[0] && (
                          <Button
                            mr='6'
                            skin='secondary'
                            onClick={sectionPrimaryCallback()}
                          >
                            {errorPageData.features[0].title}
                          </Button>
                        )}
                        {errorPageData.features[1] && (
                          <Button
                            skin='primary'
                            onClick={sectionSecondaryCallback()}
                          >
                            {errorPageData.features[1].title}
                          </Button>
                        )}
                      </Box>
                    </Column>
                  </Row>
                </GridContainer>
              </BoundedContent>
              {errorPageBackgroundImage && (
                <FluidImage
                  className={styles.wixErrorPageHeroBackgroundImage}
                  src={errorPageBackgroundImage.mediaUrl}
                  alt={errorPageBackgroundImage.altText}
                />
              )}
            </Box>
          </Box>
        )}
      </>
    </Redirect>
  )
}

export default WixWebsitesErrorPage
